import React, { useState, useCallback, useEffect } from 'react';
import {
  Link,
  useStaticQuery,
  graphql,
} from 'gatsby';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  spacing,
  mediaQuery,
  breakpoint,
} from 'styles';

import { shuffle } from 'utils';

import {
  Card,
  CardContent,
  Image,
  Subtitle,
  Description,
} from 'components';

import {
  Section,
} from '../../home/Home.styles';

type QueryNode = {
  node: {
    id: string,
    fields: {
      slug: string,
    },
    frontmatter: {
      title: string,
      category: string,
      coverImage: string,
      calendar: string,
    },
  };
};

type Query = {
  allMdx: {
    edges: QueryNode[],
  };
};

type MembersByCategory = {
  [category: string]: {
    [slug: string]: Members,
  },
};

type Members = {
  slug: string; 
  title: string;
  category: string;
  coverImage: string;
  calendar: string;
};

const Container = styled.section`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 6%;

  ${breakpoint(mediaQuery.tablet)} {
    padding: 0 8%;
  }
`;

const SubSection = styled.section`
  margin-bottom: ${spacing.chapter}px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const CategoryTitle = styled(Subtitle)`
  font-size: 20px !important;
  letter-spacing: 0 !important;
  margin-bottom: ${spacing.row + spacing.leading}px;

  ${breakpoint(mediaQuery.tablet)} {
    font-size: 24px !important;
  }
`;

const CardContainer = styled(Container)`
  ${breakpoint(mediaQuery.tablet, 'max')} {
    white-space: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-snap-destination: 50% 50%;
    scroll-padding: 10px;

    ${Card} {
      width: 300px;
      margin-right: ${spacing.row}px;
      scroll-snap-coordinate: 50% 50%;
      scroll-snap-align: center;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  ${breakpoint(mediaQuery.tablet)} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${spacing.section}px;
  }
`;

const CurriculumList = () => {
  const [list, setList] = useState<Members[]>([]);
  const [listByCategory, setListByCategory] = useState<MembersByCategory>({});

  const {
    allMdx: {
      edges,
    },
  }: Query = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              category
              coverImage
              calendar
            }
          }
        }
      }
    }
  `);

  const getStartDate = useCallback(
    (calendar: string) => (
      calendar.split(' ')
        .splice(0, 2)
        .join(' ')
    ),
    [],
  );

  useEffect(() => {
    const updatedList = shuffle(edges);
    setList(updatedList);

    const updatedListByCategory = edges.reduce((
      obj: MembersByCategory,
      item,
    ) => {
      const {
        node: {
          fields: {
            slug,
          },
          frontmatter: {
            title,
            category,
            coverImage,
            calendar,
          },
        },
      } = item;

      return {
        ...obj,
        [category]: {
          ...(obj[category] || {}),
          [slug]: {
            slug,
            title,
            category,
            coverImage,
            calendar,
          },
        },
      };
    }, {});

    setListByCategory(updatedListByCategory);
  }, []);

  const categories = shuffle(Object.keys(listByCategory));

  return (
    <Section
      css={css`
        padding-top: 0;
        min-height: 100vh;
      `}
    >
      {categories.map(category => {
        const list = shuffle((
          Object.values(listByCategory[category])
        ));

        return (
          <SubSection key={category}>
            <Container>
              <CategoryTitle>
                {category}
              </CategoryTitle>
            </Container>
            <CardContainer>
              {list.map(({
                slug,
                title,
                coverImage,
                calendar,
              }) => (
                <Card
                  key={slug}
                  as={Link}
                  to={slug}
                  data-gtm='click.curriculumList'
                >
                  <Image
                    css={{
                      width: '100%',
                      height: '210px',
                      marginBottom: `${spacing.row + spacing.leading}px`,
                    }}
                    src={coverImage}
                    alt={title}
                  />
                  <CardContent>
                    <Subtitle>
                      {title}
                    </Subtitle>
                    <Description>
                      {`${getStartDate(calendar)}부터 시작`}
                    </Description>
                  </CardContent>
                </Card>
              ))}
            </CardContainer>
          </SubSection>
        );
      })}
    </Section>
  );
};

export default CurriculumList;
