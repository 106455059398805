import React from 'react';

import styled from '@emotion/styled';
import { mediaQuery, breakpoint } from 'styles';

import CurriculumHeader from './curriculum-header';
import CurriculumList from './curriculum-list';

const CurriculumView = () => (
  <>
    <CurriculumHeader />
    <CurriculumList />
  </>
);

export default CurriculumView;
