import React from 'react';

import styled from '@emotion/styled';
import {
  spacing,
  breakpoint,
  mediaQuery,
} from 'styles';

import { Heading, Body } from 'components';

const Container = styled.section`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 6%;

  ${breakpoint(mediaQuery.tablet)} {
    padding: 0 8%;
  }
`;

const CurriculumHeader = styled.div`
  padding-top: 160px;
  margin-bottom: ${spacing.chapter}px;

  ${breakpoint(mediaQuery.mobile)} {
    padding-top: 200px;
  }
`;

const CurriculumHero = () => (
  <Container>
    <CurriculumHeader>
      <Heading>
        커리큘럼 모두 보기 🔭
      </Heading>
      <Body>
        11개의 재밌는 커리큘럼이 여러분을 기다리고 있어요 🔥
      </Body>
    </CurriculumHeader>
  </Container>
);

export default CurriculumHero;
