import styled from '@emotion/styled';
import {
  spacing,
  colors,
  mediaQuery,
  breakpoint,
} from 'styles';

import { Button, Heading, Body } from 'components';

export const Section = styled.article`
  padding-top: ${spacing.chapter * 3}px;
  padding-bottom: ${spacing.chapter}px;

  ${breakpoint(mediaQuery.tablet, 'max')} {
    ${Heading} {
      padding-right: 36px;
      word-break: keep-all;
    }
  }
`;

export const HeadingDescription = styled.div`
  margin-bottom: ${spacing.chapter}px;

  ${Body} {
    margin-bottom: ${spacing.row}px;
    padding-right: 12px;
  }

  ${breakpoint(mediaQuery.tablet - 120, 'max')} {
    br {
      display: none;
    }
  }

  ${breakpoint(mediaQuery.tablet, 'max')} {
    ${Button} {
      background-color: ${colors.primary[10]};
    }
  }

  ${breakpoint(mediaQuery.tablet)} {
    display: flex;
    align-items: flex-end;

    ${Body} {
      flex-grow: 1;
      margin-bottom: 0;
      padding-right: 36px;
    }
  }
`;
