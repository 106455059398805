import React from 'react';

import { SEO } from 'components';

import Layout from 'layouts/base';
import CurriculumView from 'layouts/curriculum';

const CurriculumPage = () => (
  <Layout>
    <SEO title='전체 커리큘럼' />
    <CurriculumView />
  </Layout>
);

export default CurriculumPage;
